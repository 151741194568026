<template>
  <div
    class="container"
    ref="container"
  >
    <p class="textMsg">
      为进一步扩大电子保函在公共资源交易领域的应用，切实降低交易成本，减轻企业负担，激发市场主体活力。我行（上海银行）于9月10日正式接入电子保函系统，正式开通电子保函服务功能，为广大企业客户提供开具电子保函业务。为确保项目顺利实施，现就有关事项通知如下：
    </p>
    <div class="table">
      <div class="title">
        <div class="dian" />
        <span>企业客户入驻需提供相关企业信息进行人工审核，如下图：</span>
      </div>
      <div>
        <a-table
          bordered
          :columns="columns"
          :data-source="data"
          :pagination="false"
        />
      </div>
    </div>
    <div class="phone">
      <div class="title">
        <div class="dian" />
        <span>业务咨询</span>
      </div>
      <p>
        邮箱：13216157642@163.com<br>
        微信号：13216157642<br>
        电子保函平台联系电话：13216157642 <span>/</span> 010-64789983
      </p>
    </div>
    <div class="wain">
      <a-icon
        type="info-circle"
        class="wainIcon"
      />
      <p>上述材料提供后，我们会在2个工作日内进行回复，节假日、非工作日时间内提交的依次顺延提交审核。</p>
    </div>
    <div class="btn">
      <button @click="back">
        返回
      </button>
    </div>
  </div>
</template>

<script>

const renderContent = (value, row, index) => {
  const obj = {
    children: value,
    attrs: {},
  };
  if (index === 8) {
    obj.attrs.colSpan = 0;
  }
  return obj;
};

export default {
  name: 'EntCertForm',
  data() {
    return {
      data: [
        {
          num: '1',
          key: '1',
          list: '营业执照',
          type: '影像件',
          obj: '企业',
        },
        {
          num: '2',
          key: '2',
          list: '身份证正/反面',
          type: '影像件',
          obj: '法定代表人',
        },
        {
          key: '3',
          num: '3',
          list: '身份证正/反面',
          type: '影像件',
          obj: '企业实际受益人',
        },
        {
          key: '4',
          num: '4',
          list: '身份证正/反面',
          type: '影像件',
          obj: '业务经办',
        },
        {
          key: '5',
          num: '5',
          list: '对公账户',
          type: '开通非接服务材料',
          obj: '企业在任意银行账户及开户行名称',
        },
        {
          key: '6',
          num: '6',
          list: '手机号码',
          type: '开通非接服务材料',
          obj: '法定代表人、受益人、业务经办人',
        },
        {
          key: '7',
          num: '7',
          list: '股东',
          type: '开通非接服务材料',
          obj: '股东身份证信息和持股比例',
        },
        {
          key: '8',
          num: '8',
          list: '公司过去1年的财报',
          type: '开通非接服务材料',
          obj: '企业',
        },
        {
          key: '9',
          list: '以上均为必要信息，为更顺畅的办理业务，请企业预先准备好上述资料',
        },
      ],
      columns: [
        {
          title: '序号',
          dataIndex: 'num',
          align: 'center',
          width: 100,
          customRender: renderContent,
        },
        {
          title: '资料清单',
          dataIndex: 'list',
          customRender: (text, row, index) => {
            if (index < 8) {
              return <span>{text}</span>;
            }
            return {
              children: <span>{text}</span>,
              attrs: {
                colSpan: 9,
              },
            };
          },
        },
        {
          title: '资料类型',
          dataIndex: 'type',
          customRender: renderContent,
        },
        {
          title: '涉及对象',
          dataIndex: 'obj',
          customRender: renderContent,
        },
      ],
    };
  },
  mounted() {

  },
  methods: {
    back() {
      this.$router.go(-1);
    },
  },
};
</script>
<style lang="less" scoped>
* {
  margin: 0;
  padding: 0;
}
.container{
  background-color: #fff;
  margin: 30px auto;
  min-height: 700px;
  display: flex;
  flex-direction: column;
  .textMsg {
    margin: 42px 40px 0;
    font-size: 14px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #333333;
  }
  .title {
    display: flex;
    .dian {
      width: 6px;
      height: 6px;
      background: #007CE2;
      border-radius: 3px;
      margin: auto 0;
    }
    span {
      font-size: 14px;
      font-family: Microsoft YaHei;
      font-weight: bold;
      color: #333333;
      margin-left: 7px;
    }
  }
  .table {
    margin: 38px 40px 0;
    /deep/ .ant-table-thead > tr > th {
      padding: 9px 30px;
      font-size: 14px;
      font-family: Microsoft YaHei;
      font-weight: bold;
      color: #666666;
    }
    /deep/ .ant-table-tbody > tr > td {
      padding: 9px 30px;
      font-size: 14px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #333333;
    }
    /deep/ .ant-table-tbody > tr:nth-last-child(1) > td > span {
      font-size: 14px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #A2B0C9;
    }
  }
  .phone {
    margin: 38px 40px 0;
    p {
      margin: 10px 0 0 12px;
      font-size: 14px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #333333;
      span {
        color: #CCCCCC;
      }
    }
  }
  .wain {
    margin: auto;
    width: 1100px;
    height: 40px;
    background: #FFFBF3;
    margin-top: 30px;
    position: relative;
    display: flex;
    display: -webkit-flex;
    .wainIcon {
      width: 12px;
      height: 12px;
      margin: auto 10px auto 30px;
      color: #E7B24B;
    }
    p {
      margin: auto 0;
      font-size: 14px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #E7B24B;
    }
    }
  .btn {
    margin: 0 40px;
    border-top: 1px solid #EBF2F7;
    display: flex;
    justify-content: center;
    margin-top: 40px;
    button {
      margin: 36px 0 50px;
      border: none;
      outline: none;
      width: 200px;
      height: 44px;
      background: #007CE2;
      border-radius: 4px;
      font-size: 16px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #FFFFFF;
    }
  }
}


</style>
